"use client";

import { useState } from "react"; // Import useState
import Image from "next/image";
import Link from "next/link";

const IntroSection = ({
  leftposition = true,
  title = "Creative Web Design Solutions",
  colouredTitle = "by Prateeksha",
  imageSrc = "",
  link = "",
  children,
}) => {
  const fallbackImages = [
    "/images/intro/affordable-website-design-services.jpg",
    "/images/intro/custom-website-design.jpg",
    "/images/intro/digital-marketing.jpg",
    "/images/intro/ecommerce-web-design-company.jpg",
    "/images/intro/high-performance-web-applications.jpg",
    "/images/intro/react-development-services.jpg",
    "/images/intro/result-driven-website-design.jpg",
    "/images/intro/shopify-expert.jpg",
    "/images/intro/shopify-store.jpg",
    "/images/intro/web-design-and-development-in-mumbai.jpg",
    "/images/intro/web-design-and-development.jpg",
    "/images/intro/web-design-company-in-andheri.jpg",
    "/images/intro/web-design-services.jpg",
    "/images/intro/website-design.jpg",
    "/images/intro/wordpress-website-design.jpg",
  ];

  // Use fallback image if imageSrc is not provided
  const [selectedImage, setSelectedImage] = useState(
    imageSrc ||
      fallbackImages[Math.floor(Math.random() * fallbackImages.length)]
  );

  const handleError = () => {
    const randomFallbackImage =
      fallbackImages[Math.floor(Math.random() * fallbackImages.length)];
    setSelectedImage(randomFallbackImage);
  };

  return (
    <section className="bg-white flex flex-col justify-center py-8 md:py-12 lg:py-16">
      <div
        className={`max-w-screen-2xl mx-auto flex flex-col md:flex-row items-center justify-between p-2 md:p-8 ${
          !leftposition ? "md:flex-row-reverse" : ""
        }`}
      >
        <div className={`${!leftposition ? "md:w-2/3" : "md:w-1/3"} `}>
          <div>
            <Image
              src={selectedImage}
              alt={`${title} ${colouredTitle}`}
              onError={handleError}
              width={600}
              height={650}
              className="text-right mx-auto"
              quality={75}
              sizes="(max-width: 600px) 100vw, (min-width: 600px) 50vw"
            />
          </div>
        </div>
        <div
          className={`${
            !leftposition ? "md:w-1/3" : "md:w-2/3"
          } mt-4 md:mt-0 mx-4 md:mx-0 pl-0 md:pl-20`}
        >
          <h2 className="font-bold text-neutral-800 mb-6">
            {title}{" "}
            <span className="text-colorOrange-700">{colouredTitle}</span>
          </h2>
          {children}
          {link && (
            <Link
              href={link}
              className="mt-4 inline-block bg-gray-800 text-white px-4 py-2 rounded-md"
            >
              Read More
            </Link>
          )}
        </div>
      </div>
    </section>
  );
};

export default IntroSection;
