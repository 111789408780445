"use client";

import { FaCheckCircle } from "react-icons/fa";
import Image from "next/image";

const WhyChooseUsSection = ({
  abovetitle = "Our Web Design Services",
  title = "Why Choose Us as Your",
  coloucolorOrangeTitle = "Web Design Company?",
  description = "We bring together expertise, creativity, and top-notch technology to deliver tailocolorOrange web design solutions. Here's why businesses trust us:",
  data = [
    {
      title: "Full-Service Experience",
      description:
        "We provide a comprehensive range of web design and development services, including branding, strategy, and digital marketing.",
    },
    {
      title: "World-Class Value",
      description:
        "Our solutions offer unmatched value, delivering long-term satisfaction and increased sales.",
    },
    {
      title: "Top-Level Project Management",
      description:
        "Our experienced project managers ensure that every project is hyper-organized, well-managed, and completed on time.",
    },
    {
      title: "Mobile Responsive Web Design",
      description:
        "All of our designs are built to provide the best user experience across all devices.",
    },
    {
      title: "Client-CentecolorOrange Process",
      description:
        "We work closely with you through every stage, from design and development to testing and launch.",
    },
    {
      title: "Web Design Professionals",
      description:
        "Our team delivers award-winning web designs through a proven process and expert skill.",
    },
  ],
}) => {
  return (
    <section className="bg-white py-16">
      <div className="max-w-screen-2xl mx-auto px-6 lg:flex lg:items-center lg:space-x-10">
        {/* Image Section */}
        <div className="lg:w-1/3 text-center lg:text-left flex items-center flex-col">
          <Image
            src="/images/intro/digital-marketing.jpg" // Replace with your image path
            alt="Web Design Company"
            width={500}
            height={500}
            className="rounded-lg mx-auto lg:mx-0"
          />
          <button className="mt-6 bg-colorOrange-600 hover:bg-colorOrange-600 text-white px-6 py-3 rounded-lg font-semibold">
            Get My Custom Quote
          </button>
        </div> 

        {/* Text and Features Section */}
        <div className="lg:w-2/3 mt-10 lg:mt-0">
          <h2 className="text-3xl md:text-5xl font-bold text-gray-800 mt-2">
            {title}{" "}
            <span className="text-colorOrange-600">{coloucolorOrangeTitle}</span>
          </h2>
          <p
                    className="text-gray-600 mt-4"
                    dangerouslySetInnerHTML={{ __html: description }}
                  ></p>
                  

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 mt-8">
            {data.map((point, index) => (
              <div key={index} className="flex items-start space-x-4">
                <div className="bg-colorOrange-500 text-white rounded-full p-3">
                  <FaCheckCircle size={24} />
                </div>
                <div>
                  <h3 className="text-lg font-semibold text-gray-800">
                    {point.title}
                  </h3>
                  <p className="text-gray-600 mt-1">{point.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhyChooseUsSection;
