"use client";
import Image from "next/image";

const CallToAction = ({
  version = 1, // default version
  title = "Transform Your Online Presence Today!",
  description = "Is your website producing results? We, as Web Designers offer cutting-edge web design and would love to help you update your online presence. Start expanding your consumer base. Make contact immediately!",
  buttonText = "Contact Us Now",
  buttonLink = "/request-a-quote",
  image = "/images/cta/ecommerce-website-design.png",
}) => {
  switch (version) {
    case 1:
      return (
        <section className="my-6">
          <div className="max-w-screen-xl mx-5 lg:mx-auto mt-16 bg-colorOrange-500 rounded-3xl relative overflow-hidden">
            <div className="relative border-2 border-white rounded-3xl">
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3 md:gap-6 lg:gap-8 items-center">
                <div className="lg:col-span-2 font-bold text-black order-2 md:order-1 pl-10">
                  <h3 className="font-semibold text-lg md:text-3xl lg:text-5xl">
                    {title}
                  </h3>
                  <p
                    className="font-normal mt-2 text-sm md:text-base lg:text-lg"
                    dangerouslySetInnerHTML={{ __html: description }}
                  />
                  <div className="mt-6 md:mt-6">
                    <a
                      href={buttonLink}
                      className="inline-block font-bold text-white bg-neutral-800 hover:bg-orange-700 rounded-xl px-8 py-2 lg:px-12 lg:py-4 transition-colors duration-300"
                    >
                      {buttonText}
                    </a>
                  </div>
                </div>
                <div className="col-span-1 order-1 md:order-2">
                  <Image
                    src="/images/cta/ecommerce-website-design.png"
                    className="w-auto right-0 bottom-0 max-w-96"
                    alt={title}
                    width={460}
                    height={550}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      );

    case 2:
      return (
        <section className="bg-gray-100 py-8 md:py-16 lg:py-20 mt-24">
          <div className="max-w-screen-xl mx-5 lg:mx-auto bg-colorOrange-500 rounded-3xl z-20 relative">
            <Image
              src="/images/cta/nextjs-website-development.png"
              className=" md:absolute w-auto right-0 bottom-0 max-w-96"
              width={450}
              height={550}
              alt={title}
            />
            <div className=" border-0 md:border-2 border-none md:border-white rounded-3xl p-6 md:p-12">
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3 md:gap-6 lg:gap-10 items-center justify-between">
                <div className="lg:col-span-2 text-black">
                  <h3 className="font-semibold">{title}</h3>
                  <p dangerouslySetInnerHTML={{ __html: description }}></p>
                  <div className="mt-6 md:mt-8">
                    <a
                      href={buttonLink}
                      className="font-bold text-white text-base bg-neutral-800 hover:bg-colorOrange-700 rounded-xl px-8 py-2 lg:px-12 lg:py-4"
                    >
                      {buttonText}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      );

    case 3:
      return (
        <section className="bg-gray-100 py-8 md:py-16 lg:py-36 mt-12">
          <div className="max-w-screen-xl mx-5 lg:mx-auto bg-neutral-700 rounded-3xl z-20 relative">
            <Image
              src="/images/cta/webdesign-services.png"
              className="absolute w-auto right-0 bottom-0 max-w-96"
              width={450}
              height={550}
              alt={title}
            />
            <div className="border-2 border-white rounded-3xl p-6 md:p-12 text-white">
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3 md:gap-6 lg:gap-10 items-center justify-between">
                <div className="lg:col-span-2 font-bold text-black">
                  <h3 className="font-semibold text-white">{title}</h3>
                  <p className="text-white">{description}</p>
                  <div className="mt-6 md:mt-8">
                    <a
                      href={buttonLink}
                      className="text-neutral-800 bg-colorOrange-500 hover:bg-colorOrange-700 rounded-xl px-8 py-2 lg:px-12 lg:py-4"
                    >
                      {buttonText}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      );

    case 4:
      return (
        <section className="py-12 md:py-16 lg:py-20 mt-28">
          <div className="max-w-screen-xl mx-5 lg:mx-auto bg-neutral-800 rounded-3xl z-20 relative">
            <Image
              src="/images/cta/custom-website-design.png"
              className="absolute w-auto left-20 bottom-0 max-w-96"
              width={450}
              height={550}
              alt={title}
            />
            <div className="border-2 border-white rounded-3xl p-6 md:p-12 text-white">
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-3 md:gap-6 lg:gap-10 items-center justify-between">
                <div className="col-span-2"></div>
                <div className="lg:col-span-2 font-bold text-black">
                  <h3 className="font-semibold bg-neutral-800 text-colorOrange-700">
                    {title}
                  </h3>
                  <p className="text-white font-normal">{description}</p>
                  <div className="mt-6 md:mt-8">
                    <a
                      href={buttonLink}
                      className="font-bold text-neutral-800 bg-colorOrange-500 hover:bg-colorOrange-700 rounded-xl px-8 py-2 lg:px-12 lg:py-4"
                    >
                      {buttonText}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      );

    case 5:
      return (
        <section className="py-12 md:py-16 lg:py-20 mt-28">
          <div className="max-w-screen-xl mx-5 lg:mx-auto bg-neutral-800 rounded-3xl z-20 relative">
            <Image
              src="/images/cta/digital-marketing.png"
              className="absolute w-auto left-0 bottom-24"
              width={450}
              height={550}
              alt={title}
            />
            <div className="border-2 border-white rounded-3xl p-6 md:p-12 text-white">
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-3 md:gap-6 lg:gap-10 items-center justify-between">
                <div className="col-span-2"></div>
                <div className="lg:col-span-2 font-bold text-black">
                  <h3 className="font-semibold bg-neutral-800 text-colorOrange-700">
                    {title}
                  </h3>
                  <p className="text-white font-normal">{description}</p>
                  <div className="mt-6 md:mt-8">
                    <a
                      href={buttonLink}
                      className="font-bold text-neutral-800 bg-colorOrange-500 hover:bg-colorOrange-700 rounded-xl px-8 py-2 lg:px-12 lg:py-4"
                    >
                      {buttonText}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      );

    case 6:
      return (
        <section className="py-12 md:py-16 lg:py-20 mt-28">
          <div className="max-w-screen-xl mx-5 lg:mx-auto bg-neutral-800 rounded-3xl z-20 relative">
            <Image
              src="/images/cta/affordable-website-design.png"
              className="absolute w-auto left-20 bottom-0"
              width={450}
              height={550}
              alt={title}
            />
            <div className="border-2 border-white rounded-3xl p-6 md:p-12 text-white">
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-3 md:gap-6 lg:gap-10 items-center justify-between">
                <div className="col-span-2"></div>
                <div className="lg:col-span-2 font-bold text-black">
                  <h3 className="font-semibold bg-neutral-800 text-colorOrange-700">
                    {title}
                  </h3>
                  <p className="text-white font-normal">{description}</p>
                  <div className="mt-6 md:mt-8">
                    <a
                      href={buttonLink}
                      className="font-bold text-neutral-800 bg-colorOrange-500 hover:bg-colorOrange-700 rounded-xl px-8 py-2 lg:px-12 lg:py-4"
                    >
                      {buttonText}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      );

    case 7:
      return (
        <section className="my-10">
          <div className="max-w-screen-xl mx-5 lg:mx-auto mt-16 bg-colorOrange-500 rounded-3xl relative overflow-hidden">
            <div className="absolute w-auto right-0 left-0">
              <Image
                src="/images/cta/website-design-services.png"
                fill
                alt={title}
                width={450}
                height={550}
              />
            </div>
            <div className="relative border-2 border-white rounded-3xl">
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3 md:gap-6 lg:gap-10 items-center">
                <div className="lg:col-span-2 font-bold text-black order-2 md:order-1 pl-10">
                  <h3 className="font-semibold text-lg md:text-3xl lg:text-5xl">
                    {title}
                  </h3>
                  <p
                    className="font-normal mt-4 text-sm md:text-base lg:text-lg"
                    dangerouslySetInnerHTML={{ __html: description }}
                  />
                  <div className="mt-6 md:mt-8">
                    <a
                      href={buttonLink}
                      className="inline-block font-bold text-white bg-neutral-800 hover:bg-orange-700 rounded-xl px-8 py-2 lg:px-12 lg:py-4 transition-colors duration-300"
                    >
                      {buttonText}
                    </a>
                  </div>
                </div>
                <div className="col-span-1 order-1 md:order-2">
                  <Image
                    src={image}
                    className="object-cover"
                    alt={title}
                    width={460}
                    height={550}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      );

    default:
      return null;
  }
};

export default CallToAction;
